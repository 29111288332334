.nvidia {
    animation: MoveUpDown2 4s linear infinite;
    position: absolute;
    z-index: 2;
    width: 40%; 
    top: 25%;
    right: 42%;
  }
  
  @keyframes MoveUpDown2 {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateX(-20px);
    }
  }

  .jordan {
    animation: MoveUpDown2 4s linear infinite;
    position: absolute;
    z-index: 2;
    width: 40%; 
    top: 58%;
    right: 52%;
  }

.phone2 {
  position: relative;
  width: 70%;
  transform: translateX(50%);
  z-index: 1;
}