

.stonks {
    position: absolute;
    width: 25%;
    transform: translateX(25%);
    z-index: 1;
    
}

.sell {
    position: absolute;
    width: 25%;
    transform: translate(265%, 175%);
    z-index: 1;
}

.buy {
    position: absolute;
    width: 25%;
    transform: translate(20%, 440%);
    z-index: 1;
}

.QR {
    position: absolute;
    width: 30%;
    transform: translate(225%, 180%);
    z-index: 1;
}

.jordanshoe {
    position: relative;
    width: 80%;
    transform: translateX(25%);
    z-index: 1;
}