.sold {
    animation: MoveUpDown 4s linear infinite;
    position: absolute;
    z-index: 0;
    width: 42%; 
    top: 9%;
    right: 62%;
  }
  
  @keyframes MoveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }

  .playstation {
    animation: MoveUpDown 4s linear infinite;
    position: absolute;
    z-index: 2;
    width: 62%; 
    top: 50%;
    right: 42%;
  }

.phone {
  position: relative;
  transform: translateX(10%);
  z-index: 1;
}

.btn-default:hover {
  background-color: aliceblue;
}