// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

* {
    scrollbar-width: 18px;/*thin;*/
    scrollbar-color: black #fbfbfd;
  }
  *::-webkit-scrollbar {
    width: 18px;
  }
  arn start
  *::-webkit-scrollbar-track {
    background: #101010;        /* color of the tracking area */
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #191919;    /* color of the scroll thumb */
    border-radius: 1px;       /* roundness of the scroll thumb */
   /* border: 1px solid #000066;  *//* creates padding around scroll thumb */
  }
