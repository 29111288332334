
.checkout1 {
    position: relative;
    width: 70%;
    transform: translateX(15%);
    z-index: 1;
}

.checkout2 {
    position: relative;
    width: 70%;
    transform: translateX(25%);
    z-index: 1;
}

.checkout3 {
    position: relative;
    width: 70%;
    transform: translateX(35%);
    z-index: 1;
}

.checkout4 {
    position: relative;
    width: 70%;
    transform: translateX(45%);
    z-index: 1;
}